import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout"

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faClock} from "@fortawesome/free-regular-svg-icons"
import {faChevronLeft, faChevronRight, faCheckSquare, faBasketballBall} from "@fortawesome/free-solid-svg-icons"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"

import useContentfulImage from "../utils/useContentfulImage"

// import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";

import Seo from "../components/seo"

import "../components/gameresult.css"

const options = {
    renderText: text => {
        return text.split("\n").reduce((children, textSegment, index) => {
            return [...children, index > 0 && <br key={index} />, textSegment]
        }, [])
    },
    renderNode: {
        [BLOCKS.HEADING_2]: (node, children) => (
            <h2>
                <FontAwesomeIcon icon={faCheckSquare} />
                {children}
            </h2>
        ),
        [BLOCKS.EMBEDDED_ASSET]: node => (
            <GatsbyImage
                image={useContentfulImage(node.data.target.fields.file["ja-JP"].url)}
                alt={
                    node.data.target.fields.description
                    ? node.data.target.fields.description["ja-JP"]
                    : node.data.target.fields.title["ja-JP"]
                } />
        ),
    }

}

const resultPost = ({data, pageContext, location}) => (
    <Layout>

        <Seo
            pagetitle={`${data.contentfulGameResult.title} | 試合結果`}
            pagedesc={data.contentfulGameResult.outline.outline}
            pagepath={location.pathname}
            blogimg={`https:${data.contentfulGameResult.eyecatch.file.url}`}
            pageimgw={data.contentfulGameResult.eyecatch.file.details.image.width}
            pageimgh={data.contentfulGameResult.eyecatch.file.details.image.height}
        />

        <div className="eyecatch">
            <figure>
                <GatsbyImage
                    image={data.contentfulGameResult.eyecatch.gatsbyImageData}
                    alt={data.contentfulGameResult.eyecatch.description}
                    style={{height:"100%"}} />
            </figure>
        </div>

        <article className="content">
            <div className="container">
                <h1 className="bar">{ data.contentfulGameResult.title }</h1>

                <aside className="info">
                    <time dateTime={ data.contentfulGameResult.publishDate }>
                        <FontAwesomeIcon icon={faClock} />
                        { data.contentfulGameResult.publishDateJP }
                    </time>

                    <div className="cat">
                        <FontAwesomeIcon icon={faBasketballBall} />
                        <ul>
                            {data.contentfulGameResult.category.map(cat => (
                                <li className={cat.categorySlug} key={cat.id}>
                                    <Link to={`/gamecat/${cat.categorySlug}/`}>{cat.category}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </aside>

                <div className="postbody">
                    { documentToReactComponents(data.contentfulGameResult.content.json, options) }
                </div>
                <div className="postbody">
                    <div className="p-result-data" dangerouslySetInnerHTML={{ __html: data.contentfulGameResult.resultdetail.childMarkdownRemark.html }} />
                </div>

                <ul className="postlink">
                    {(pageContext.next && (
                        <li className="prev">
                            <Link to={`/result/${pageContext.next.slug}/`} rel="prev">
                                <FontAwesomeIcon icon={faChevronLeft} />
                                <span>{pageContext.next.title}</span>
                            </Link>
                        </li>
                    )) || <li></li> }

                    {(pageContext.previous && (
                        <li className="prev">
                            <Link to={`/result/${pageContext.previous.slug}/`} rel="next">
                                <span>{pageContext.previous.title}</span>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </Link>
                        </li>
                    )) || <li></li> }
                </ul>

            </div>
        </article>

    </Layout>
)
export default resultPost;

export const query = graphql`
    query($id: String!) {
        contentfulGameResult(id: { eq: $id }){
            title
            publishDateJP: publishDate(formatString: "YYYY年MM月DD日")
            publishDate
            category {
              category
              categorySlug
              id
            }
            outline{
                outline
            }
            resultdetail {
              childMarkdownRemark {
                html
              }
            }
            eyecatch{
              description
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                placeholder: BLURRED
                width: 500
                layout: FULL_WIDTH
              )
              file {
                details {
                  image {
                    height
                    width
                  }
                }
                url
              }
            }
            content{
                raw
            }
        }
    }
`