import { useStaticQuery, graphql } from "gatsby"

// export default assetUrl => {
const UrlLayout = ({assetUrl}) =>{
    const { allContentfulAsset } = useStaticQuery(graphql`
    query {
      allContentfulAsset {
        nodes {
          file {
            url
          }
          description
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            width: 785
            layout: FIXED
          )
        }
      }
    }
    `)
    return allContentfulAsset.nodes.find(n => n.file.url === assetUrl).fluid
}
export default UrlLayout